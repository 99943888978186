import { Component, OnInit, ViewChild  } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IssuePolicyService } from '../../../services-http/issue-policy/issue-policy.service';
import { ModalDirective  } from 'ng-uikit-pro-standard';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuItem } from 'primeng/api';
import { SelectItem } from 'primeng/api';
import { CacProductService } from '../../../services-http/cac-product/cac-product.service';
import { whitespaceValidation } from '../../../customValidation/whitespace.validation';
import { QuotationService } from '../../../services-http/quotation/quotation.service';
import { debounceTime } from "rxjs/operators";
import {environment} from '../../../../environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-issue-policy',
  templateUrl: './CTPL.component.html',
  styleUrls: ['./CTPL.component.scss']
})
export class CTPLComponent implements OnInit {
  @ViewChild('basicModal') basicModal: ModalDirective;

  login_id:any
  constructor(
    private IssuePolicy: IssuePolicyService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private router: Router,
    private cacProduct:CacProductService,
    private quotation: QuotationService,
  ) {
    route.params.subscribe(val => {
      this.quoteId=val.quoteId;
      this.login_id=val.login_id;
    });
  }

  items: MenuItem[];
  types: SelectItem[];
  dialogIndex:number = 0;
  assuredDetailsMemberAssociate: FormGroup;
  assuredDetailsCorporate:FormGroup;
  vehicleDetails: FormGroup;
  User = JSON.parse(localStorage.getItem('currentUser'));
  vehicleListing:any = [];
  numOfPassenger:any = 0;
  checkbox:any = [];
  prodcuctId:any;
  basic_premium:any = 0;
  amount_to_pay:any =0;
  total_calc_commission:any = 0
  waived_commission:any = 0;
  loading:boolean = false;
  memberType:any = "memberAssociateMember";
  quoteId:any;
  url=this.router.url;
  furl;
  lateReg:any=false;
  posting_type:any=0;
  clicked:boolean=false;
  productInfo:any={points:{credit:0,regular:0}};
  math=Math;
  quoteData:any;
  prev_policy_no:any ='';
  new_business:boolean = false; 
  owner:boolean = true;
  searchListShow:boolean = false;
  searchitems:any={};
  searchResult:any = [];
  isLoading:boolean = false;
  referer:any='';
  regtype:any=false;
  formData = new FormData();


  nextPage(){this.dialogIndex++;}

  previousPage(){this.dialogIndex--;}

  async ngOnInit() {

    this.getProductInfo();

    this.furl=this.url.split('/')[1];

    this.items = [
      {label: 'Assured Details'},
      {label: 'Vehicle Details'},
      {label: 'Benefit Details'},
      {label: 'Computation'}
    ];
  
    this.assuredDetailsMemberAssociate = new FormGroup({
      // assured: new FormControl('memberAssociateMember', [Validators.required]),
      lastname: new FormControl('', Validators.required),
      firstname: new FormControl('', Validators.required),
      middlename: new FormControl('', Validators.required),
      suffix: new FormControl(''),
      gender: new FormControl('', Validators.required),
      contact: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(11), Validators.maxLength(11)] ),
      email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
      address: new FormControl('', Validators.required),
      zipCode: new FormControl('', Validators.required),
      cid: new FormControl(''),
      branch: new FormControl(''),
      remarks: new FormControl(''),
      lastname_owner: new FormControl(''),
      firstname_owner: new FormControl(''),
      middlename_owner: new FormControl(''),
      coop_no: new FormControl(''),
    });


    this.assuredDetailsCorporate = new FormGroup({
      assuredName: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
      address: new FormControl('', Validators.required),
      contact: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(11), Validators.maxLength(11)] ),
      zipCode: new FormControl('', Validators.required),
      branch: new FormControl(''),
      // cid: new FormControl(''),
      coop_no: new FormControl(''),
    });

    
    this.vehicleDetails = new FormGroup({
      classification: new FormControl('', Validators.required),
      vehicle: new FormControl('', Validators.required),
      yearModel: new FormControl('', [Validators.required,Validators.pattern("^[0-9]*$")]),
      series: new FormControl('', Validators.required),
      make: new FormControl('', Validators.required),
      color: new FormControl('', Validators.required),
      plateNo: new FormControl('', [Validators.required,whitespaceValidation.cannotContainSpace, Validators.maxLength(8)]),
      bodyType: new FormControl('', Validators.required),
      MVFile: new FormControl('', [Validators.required, Validators.minLength(15), Validators.maxLength(15),whitespaceValidation.cannotContainSpace] ),
      chassis: new FormControl('', [Validators.required,whitespaceValidation.cannotContainSpace]),
      engineNo: new FormControl('', [Validators.required,whitespaceValidation.cannotContainSpace]),
      effectivity_date: new FormControl('')
    });


 

    this.assuredDetailsMemberAssociate.get("cid").valueChanges
      .pipe(debounceTime(3000))
      .subscribe(res => {
        if(res){
          const Http = new XMLHttpRequest();
          const url= environment.tagumAPI+'/get-profile/'+res;
          Http.open("GET", url);
          Http.setRequestHeader( 'Content-Type', 'application/json');
          Http.setRequestHeader("Authorization", `Bearer ${environment.token_tagum}`);
          Http.send();
  
          Http.onreadystatechange = (e)=>{
            let assured=JSON.parse(Http.response);

            if(Http.status==200){
              this.assuredDetailsMemberAssociate
                .patchValue({
                  lastname: assured.Message.appData.lastname,
                  firstname: assured.Message.appData.firstname,
                  middlename: assured.Message.appData.middlename,
                  suffix: assured.Message.appData.suffix,
                  gender: assured.Message.appData.gender.toLowerCase(),
                  contact: assured.Message.appData.contactno,
                  email:assured.Message.appData.email,
                  address: assured.Message.appData.address,
                  zipCode: assured.Message.appData.zipcode
              });
            }
            else{
              this.assuredDetailsMemberAssociate.reset();
              Swal.fire(
                'Oppss',
                assured.Message +' ('+ res +')',
                'error'
              );
            }  

          }
        }
    });


    this.assuredDetailsCorporate.get("coop_no").valueChanges
      .pipe(debounceTime(2000))
      .subscribe(res => {
        if(res && typeof(res)==='string'){
         this.search(res)
        }
    });


    this.assuredDetailsMemberAssociate.get("coop_no").valueChanges
    .pipe(debounceTime(2000))
    .subscribe(res => {
      if(res && typeof(res)==='string'){
       this.search(res)
      }
  });
    
  }


  closeSearchList(){
    this.searchListShow = false;
  }

  searchItem(searhItem){

    this.searchListShow = false;
    this.searchitems = searhItem;
    this.assuredDetailsMemberAssociate.patchValue({coop_no: searhItem.CoopNum});
    this.assuredDetailsCorporate.patchValue({coop_no: searhItem.CoopNum});

    this.assuredDetailsCorporate.patchValue({assuredName: searhItem.Name});

  }
  search(res){
    this.isLoading = true;

    let data = {
      search:res
    }
        
    this.cacProduct.searchGroupClient(data).subscribe((res:any)=>{
      this.isLoading = false;
     
      this.searchListShow = true;  
      this.searchResult = res;  
    },error=>{
      this.isLoading = false;

      Swal.fire(
        'Oppss',
        'Something went wrong.',
        'error'
      );
    });
  }


  getProductInfo(){
    let data={
      variantId: 3,
      login_id : this.quoteId>0? +this.login_id: +this.User.iloginId,
      planNo   : 5
    }
    this.IssuePolicy.getProductInfo(data).subscribe((res:any)=>{
     
      if(this.quoteId>0){
        this.viewPolicyDetails();
      }

      this.productInfo=res;
      if(this.productInfo.canSave=="0"){
        Swal.fire(
          'Alert',
          'This product '+this.productInfo.varlongname+' is currently disabled.',
          'warning'
        );
      }

    },error=>{
      Swal.fire(
        'Oppss',
        'Something went wrong.',
        'error'
      );
    });
  }

 
  async viewPolicyDetails(){
    this.quoteData = await this.quotation.view_quotation(this.quoteId).toPromise();

    this.new_business = this.quoteData[0].statusid==7? true: false;

    this.prev_policy_no = this.quoteData[0].policyno;

    this.memberType=this.quoteData[0].assured.assuredType;

    this.vehicleListing = this.productInfo.prod.find(x => x.id == +this.quoteData[0].vehicle.productNo);

    if(this.quoteData[0].assured.assuredType=="memberAssociateMember"){
      this.assuredDetailsMemberAssociate.patchValue({
        lastname  :this.quoteData[0].assured.lastName,
        firstname :this.quoteData[0].assured.firstName,
        middlename:this.quoteData[0].assured.middleName,
        suffix    :this.quoteData[0].assured.suffix,
        gender    :this.quoteData[0].gender,
        contact   :this.quoteData[0].contactNo,
        email     :this.quoteData[0].emailAddress,
        address   :this.quoteData[0].address,
        zipCode   :this.quoteData[0].zipCode,
        coop_no   :+this.quoteData[0].coopNo     
      });
    }
    if(this.quoteData[0].assured.assuredType=="corporate"||this.quoteData[0].assured.assuredType=="cooperative"){
      this.assuredDetailsCorporate.patchValue({
        assuredName :this.quoteData[0].assured.assuredName,
        email       :this.quoteData[0].emailAddress,
        address     :this.quoteData[0].address,
        contact     :this.quoteData[0].contactNo,
        zipCode     :this.quoteData[0].zipCode,
        coop_no     :+this.quoteData[0].coopNo   
      });
    }


    this.vehicleDetails.patchValue({
      yearModel        :this.quoteData[0].vehicle.yearModel,
      series           :this.quoteData[0].vehicle.model,
      make             :this.quoteData[0].vehicle.make,
      color            :this.quoteData[0].vehicle.color,
      plateNo          :this.quoteData[0].vehicle.plateNo,
      bodyType         :this.quoteData[0].vehicle.bodyType,
      MVFile           :this.quoteData[0].vehicle.mvFileNo,
      chassis          :this.quoteData[0].vehicle.chassisNo,
      engineNo         :this.quoteData[0].vehicle.engineNo,
      classification   :this.quoteData[0].vehicle.productNo,
      vehicle          :this.quoteData[0].vehicle.optionNo,
      effectivity_date :moment(this.quoteData[0].effectivityDate).format('YYYY-MM-DD')
    });


  }


  get lastname() { return this.assuredDetailsMemberAssociate.get('lastname'); }
  get firstname() { return this.assuredDetailsMemberAssociate.get('firstname'); }
  get middlename() { return this.assuredDetailsMemberAssociate.get('middlename'); }
  get suffix() { return this.assuredDetailsMemberAssociate.get('suffix'); }
  get gender() { return this.assuredDetailsMemberAssociate.get('gender'); }
  get contact() { return this.assuredDetailsMemberAssociate.get('contact'); }
  get email() { return this.assuredDetailsMemberAssociate.get('email'); }
  get address() { return this.assuredDetailsMemberAssociate.get('address'); }
  get zipCode() { return this.assuredDetailsMemberAssociate.get('zipCode'); }
  get assuredName() { return this.assuredDetailsCorporate.get('assuredName'); }
  get lastname_owner() { return this.assuredDetailsMemberAssociate.get('lastname_owner'); }
  get firstname_owner() { return this.assuredDetailsMemberAssociate.get('firstname_owner'); }
  get middlename_owner() { return this.assuredDetailsMemberAssociate.get('middlename_owner'); }
  get coop_no1() { return this.assuredDetailsMemberAssociate.get('coop_no'); }


  get email1() { return this.assuredDetailsCorporate.get('email'); }
  get address1() { return this.assuredDetailsCorporate.get('address'); }
  get contact1() { return this.assuredDetailsCorporate.get('contact'); }
  get zipCode1() { return this.assuredDetailsCorporate.get('zipCode'); }
  get branch1() { return this.assuredDetailsCorporate.get('branch'); }
  get coop_no() { return this.assuredDetailsCorporate.get('coop_no'); }


  get classification() { return this.vehicleDetails.get('classification'); }
  get vehicle() { return this.vehicleDetails.get('vehicle'); }
  get yearModel() { return this.vehicleDetails.get('yearModel'); }
  get series() { return this.vehicleDetails.get('series'); }
  get make() { return this.vehicleDetails.get('make'); }
  get color() { return this.vehicleDetails.get('color'); }
  get plateNo() { return this.vehicleDetails.get('plateNo'); }
  get bodyType() { return this.vehicleDetails.get('bodyType'); }
  get MVFile() { return this.vehicleDetails.get('MVFile'); }
  get chassis() { return this.vehicleDetails.get('chassis'); }
  get engineNo() { return this.vehicleDetails.get('engineNo'); }
  get effectivity_date() { return this.vehicleDetails.get('effectivity_date'); }
  get remarks() { return this.assuredDetailsMemberAssociate.get('remarks'); }



  vehicleList(id){
    this.vehicleListing=this.productInfo.prod.find(x=>x.id==id);
  }


  benefitDetails(){
    this.numOfPassenger=0;

    this.productInfo.benefit.forEach(element => {
      let data:any = this.vehicleListing.option.find(x=>x.id==this.vehicle.value).tarrif.find(x=>x.benefitNo==element.id)

      element['checked']=element.id==30?true: false;
      element['coverage']=data.coverageFrom;
      element['premium']=data.premiumRate;
      element['noPassenger']=this.numOfPassenger;
      element['com_rate']= this.vehicleListing.commission.find(x=>x.benefit_name_id==element.id).comm_rate_max/100;
      // element['com_rate']=element.new_comm.reduce((prev,next)=>+prev+ +next.comm_rate_max,0)/100;
    });
  }



  addPassenger(){    
    if(this.numOfPassenger  >= 1 && this.numOfPassenger <=13){
      this.basicModal.hide();
    }
  }


  compute(){
    this.total_calc_commission=0;
    this.amount_to_pay=0;
    this.basic_premium=0;
    this.waived_commission=0;

    this.productInfo.benefit.forEach(element => {
      if(element.checked){
        this.basic_premium += (element.id==31? +element.premium*this.numOfPassenger: +element.premium);
      }
    });



    // other payment
    let other_payment=0;
    this.productInfo.payment_list_rates.forEach(element => {
      if(element.paymentListNo==1){
        other_payment += this.numOfPassenger>0? +(+element.rate/100*this.basic_premium).toFixed(2) : Math.round(+element.rate/100*this.basic_premium);
      }
      else{
        other_payment += element.paymentListNo==5? +element.rate/100*this.basic_premium: +element.rate;
      }

    });

    // commission
    this.productInfo.benefit.forEach(element => {
      if(element.checked){
        this.total_calc_commission += +(element.id==31?element.premium*this.numOfPassenger: element.premium)*element.com_rate;
      }
    });
    this.amount_to_pay = other_payment+this.basic_premium;
  }


  async issuePolicy(isQuote){
      this.clicked=true;
      this.spinner.show();

      let benefitDetails=[]; 
      this.productInfo.benefit.forEach(element => {
        benefitDetails.push({
          checked: element.checked,
          benefitNo: element.id,
          coverage: element.coverage,
          premium: element.id==31?+element.premium * this.numOfPassenger: +element.premium,
          commission: element.id==31?(+element.premium * this.numOfPassenger)*element.com_rate: +element.premium*element.com_rate,
          noPass: element.id==31? this.numOfPassenger:0
        });
      });
      
      
      let lto:any=this.vehicleListing.option.find(x=>x.id==this.vehicle.value);


      let ef = new Date(this.effectivity_date.value)

      let efDate = new Date(this.effectivity_date.value)
      let efDate2 = new Date(this.effectivity_date.value)
      let now = new Date();

      let expiry_date;
      let effective_date;

      let addtree = efDate.setDate(efDate.getDate()+1095);
      let oneyear = efDate2.setDate(efDate2.getDate()+365);

      let checkIfthreeyear = benefitDetails.find(x=>x.benefitNo == 34 && x.checked==true);

  

      if(this.lateReg==true){
        effective_date = moment(ef).format('YYYY-MM-DD');
        
        if(checkIfthreeyear){
          expiry_date= moment(addtree).format('YYYY-MM-DD');
        }

        else {
          expiry_date = moment(oneyear).format('YYYY-MM-DD');
        }
      }

      else{
        if(checkIfthreeyear){
          this.lateReg=true;
          effective_date = moment(now).format('YYYY-MM-DD');
          expiry_date = moment(now.setDate(now.getDate()+1095)).format('YYYY-MM-DD');
        }
        else{
          effective_date = null;
          expiry_date = null;
        }
      }

      let paymentListOther = this.productInfo.payment_list_rates;
     
      this.formData.set('assured[assuredName]', this.memberType == "memberAssociateMember"? this.assuredDetailsMemberAssociate.value.firstname +' '+ this.assuredDetailsMemberAssociate.value.middlename +' '+ this.assuredDetailsMemberAssociate.value.lastname: this.assuredDetailsCorporate.value.assuredName);
      this.formData.set('assured[createdBy]', this.quoteId>0? this.quoteData[0].agentNo: this.User.iloginId);
      this.formData.set('assured[firstName]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.firstname:"");
      this.formData.set('assured[lastName]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.lastname:"");
      this.formData.set('assured[middleName]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.middlename:"");
      this.formData.set('assured[suffix]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.suffix:"");
      this.formData.set('assured[address]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.address:this.assuredDetailsCorporate.value.address);
      this.formData.set('assured[contact]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.contact:this.assuredDetailsCorporate.value.contact);
      this.formData.set('assured[email]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.email:this.assuredDetailsCorporate.value.email);
      this.formData.set('assured[assuredType]', this.memberType);
      this.formData.set('assured[gender]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.gender:"");
      this.formData.set('assured[coopNo]',  this.quoteId>0? this.quoteData[0].coopNo: (this.assuredDetailsCorporate.value.coop_no? this.assuredDetailsCorporate.value.coop_no: this.User.coop_maincode));
      this.formData.set('assured[zipCode]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.zipCode: this.assuredDetailsCorporate.value.zipCode);
      this.formData.set('assured[cid]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.cid: "");
      this.formData.set('assured[posting_type]', this.posting_type);
      this.formData.set('assured[branch]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.branch: this.branch1.value);
      this.formData.set('assured[issuedFrom]', 'Web');
      this.formData.set('assured[isQuote]', isQuote);
      this.formData.set('assured[new_business]', this.new_business==true?'2' :'1');
      this.formData.set('assured[prev_policy_no]', this.prev_policy_no);
      this.formData.set('assured[remarks]', this.remarks.value);
      this.formData.set('assured[lastname_owner]', this.lastname_owner.value);
      this.formData.set('assured[firstname_owner]', this.firstname_owner.value);
      this.formData.set('assured[middlename_owner]', this.middlename_owner.value);
      this.formData.set('assured[id]', this.quoteId>0 && this.new_business==false ? this.quoteData[0].assuredListNo:'');
      this.formData.set('assured[quotationno]', this.quoteId>0 && this.new_business==false ?this.quoteData[0].id:'');
      this.formData.set('assured[referer]',this.referer);
      this.formData.set('assured[variantId]', this.productInfo.variantid);
      this.formData.set('assured[policy]', this.productInfo.varshortname);
      this.formData.set('vehicle[productNo]', this.vehicleDetails.get('classification').value);
      this.formData.set('vehicle[optionNo]', this.vehicleDetails.get('vehicle').value);
      this.formData.set('vehicle[bodyType]', this.vehicleDetails.get('bodyType').value);
      this.formData.set('vehicle[plateNo]', this.vehicleDetails.get('plateNo').value);
      this.formData.set('vehicle[yearModel]', this.vehicleDetails.get('yearModel').value);
      this.formData.set('vehicle[model]', this.vehicleDetails.get('series').value);
      this.formData.set('vehicle[color]', this.vehicleDetails.get('color').value);
      this.formData.set('vehicle[mvFileNo]', this.vehicleDetails.get('MVFile').value);
      this.formData.set('vehicle[chassisNo]', this.vehicleDetails.get('chassis').value);
      this.formData.set('vehicle[engineNo]', this.vehicleDetails.get('engineNo').value);
      this.formData.set('vehicle[make]', this.vehicleDetails.get('make').value);
      this.formData.set('vehicle[lateReg]',this.lateReg);
      this.formData.set('vehicle[numOfPassenger]', this.numOfPassenger);
      this.formData.set('vehicle[owner]', this.owner == true ? 'yes': 'no');
      this.formData.set('vehicle[regtype]', this.regtype);
      this.formData.set('assured[effectivityDate]', effective_date);
      this.formData.set('assured[expiryDate]', expiry_date);
      this.formData.set("LTOAuthenticateReq[mvType]" , lto.lto_mvtypecode==undefined?'C':lto.lto_mvtypecode); //mvtypecode
      this.formData.set("LTOAuthenticateReq[mvPremType]" , lto.lto_mvprem_typeno1yr==undefined?'1':lto.lto_mvprem_typeno1yr); //mbpremtype1yrs
      this.formData.set("LTOAuthenticateReq[plateNo]" ,  this.vehicleDetails.value.plateNo);
      this.formData.set("LTOAuthenticateReq[mvFileNo]" , this.vehicleDetails.value.MVFile);
      this.formData.set("LTOAuthenticateReq[engineNo]" , this.vehicleDetails.value.engineNo);
      this.formData.set("LTOAuthenticateReq[chassisNo]" , this.vehicleDetails.value.chassis);
      this.formData.set('paymentHeader[grossPremium]', this.basic_premium);
      this.formData.set('paymentHeader[netPremium]', this.amount_to_pay);
      this.formData.set('paymentHeader[waiveComm]', this.waived_commission);
      this.formData.set('benefit', JSON.stringify(benefitDetails));
      this.formData.set('paymentOther', JSON.stringify(paymentListOther));


      this.IssuePolicy.issuePolicy(this.formData).subscribe((res:any)=>{
        this.spinner.hide();
    
        if(res.data.policyno){
          this.router.navigate(['/'+this.furl+'/policy-issued']);
        } else{
          this.router.navigate(['/'+this.furl+'/issued-policy-quotation']);
        }


      },
      error=>{
        this.clicked=false;
        this.spinner.hide();

        let err=error.status == 0?{msg: 'Unable to authenticate to LTO at this moment.'}: error.error ;
        let msg='';

        Object.keys(err).forEach(function(key){
          msg+=err[key]+'<br/>';
        })
  
        Swal.fire(
          'Error',
          msg,
          'error'
        );
      });
  }


  netCommision(){
    if(this.waived_commission  > Math.round((this.total_calc_commission + Number.EPSILON) * 100) / 100){
      this.waived_commission = 0;
      Swal.fire(
        'Note!',
        'waived commission max is '+(Math.round((this.total_calc_commission + Number.EPSILON) * 100) / 100) ,
        'warning'
      );
    }
  }


  lateRegchange(){

    if(!this.lateReg){
      this.effectivity_date.setValue('');
    }


    let now = new Date();
    let expiry_date= moment(now).format('YYYY-MM-DD');
    this.vehicleDetails.patchValue({effectivity_date: expiry_date})
   
    this.vehicleDetails.controls.effectivity_date.setValidators(this.lateReg==false ? null : [Validators.required]);
    this.vehicleDetails.controls.effectivity_date.updateValueAndValidity();
  }

  NewcarOptionchange(){
    if(this.regtype){
      this.plateNo.setValue('');
      this.MVFile.setValue('');
      
    }
    this.vehicleDetails.controls.plateNo.setValidators(this.regtype==true ? null : [Validators.required]);
    this.vehicleDetails.controls.plateNo.updateValueAndValidity();
    this.vehicleDetails.controls.MVFile.setValidators(this.regtype==true ? null : [Validators.required]);
    this.vehicleDetails.controls.MVFile.updateValueAndValidity();
  }
  





}
