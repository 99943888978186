import { Component, OnInit,ViewChild } from '@angular/core';
import { AuthService } from '../../../services-http/auth/auth.service';
import { UserService } from '../../../services-http/user/user.service';
import { Router } from '@angular/router';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { NotificationService } from '../../../services-http/notification/notification.service'



@Component({
  selector: 'app-cashin-approval-officer',
  templateUrl: './cashin-approval-officer.component.html',
  styleUrls: ['./cashin-approval-officer.component.scss']
})
export class CashinApprovalOfficerComponent implements OnInit {

  @ViewChild('frame') frame: ModalDirective;

  user:any = JSON.parse(localStorage.getItem('currentUser'));
  userType:any;
  coopName:any;

  constructor(
    private authService: AuthService,
    private router: Router,
    private userService:UserService,
    public notification:NotificationService
  ) { }


  ngOnInit() {
    // this.getCoopName();
    this.refresh();
  }


  logout() {
    this.authService.logout();
  }
  
   
  gotoProfileView() {
    this.router.navigateByUrl('/cashin-approval-officer/cac-profile/' + this.user['userId'] + '/view');
  }

  // getCoopName(){
  //   this.userService.getCoopName(this.user['userId']).subscribe(res => {
  //     // console.log(res)
  //     this.coopName = res;
  //   });
  // }

  notificationRead(notification){
    this.notification.notificationRead(notification).subscribe(res =>{
      this.notification.getNotification(this.user.iloginId);
    });
  }

  refresh(){
    this.notification.getNotification(this.user.iloginId);
  }

}
