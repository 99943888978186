import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BillingService } from '../../../services-http/billing/billing.service';
import Swal from 'sweetalert2';
import { FormBuilder, Validators,FormGroup,FormControl } from '@angular/forms';
import { ModalDirective  } from 'ng-uikit-pro-standard';
import { environment } from 'src/environments/environment';
import { ProductionHistoryService } from '../../../services-http/production-history/production-history.service';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { IssuePolicyService } from '../../../services-http/issue-policy/issue-policy.service';
import { CommissionPremiumSetupService } from '../../../services-http/commission-premium-setup/commission-premium-setup.service';



@Component({
  selector: 'app-billing-generate',
  templateUrl: './billing-generate.component.html',
  styleUrls: ['./billing-generate.component.scss']
})
export class BillingGenerateComponent implements OnInit {
  @ViewChild('frame1') frame1: ModalDirective;
  @ViewChild('frame') frame: ModalDirective;


  constructor(
    private spinner: NgxSpinnerService, 
    private bill:BillingService,
    private fb: FormBuilder, 
    private productHis:ProductionHistoryService,
    private issuedpolicy:IssuePolicyService,
    private com:CommissionPremiumSetupService,
  ) { }

  ngOnInit() {

    this.productsListing();

    this.selectBranch();

    this.get_channel_type();

    this.get_unpaid_policy();
  }

  public myDatePickerOptions: IMyOptions = {
    // Your options
  };

  public myDatePickerOptions1: IMyOptions = {
    // Your options
  };

  date_from:any;
  date_to:any;
  billlists:any=0;
  user:any = JSON.parse(localStorage.getItem('currentUser'));
  reference:any;
  pdf_url:any;
  formData = new FormData();
  mg_url:any;
  url = environment.url;
  img_url:any;
  bill_id:any;
  show:number=0;
  channel_type:any=[];
  channel_name_list:any=[];
  files:any=[];
  unpaid_policy:any=[];
  insuranceType:any = [{value: '1', label: 'Life product'}, {value: '2', label: 'Nonlife product'}];
  products:any=[];
  branch:any='';
  product:any=''
  insType:any=''
  datefrom:any="2019-01-01"
  dateto:any="2025-01-01"

  datenow:any = new Date()

  



  total_gross:any =0.00;
  total_management_fee:any=0.00;
  amount_to_pay:any=0.00;
  endorsement_a:any=0.00;
  endorsement_r:any=0.00;
  selected_policy_count:any =0

  payForm= this.fb.group({
    channel_type:  ['', Validators.required],
    channel_branch:['', Validators.required],
    channel_name:  ['', Validators.required],
    amount_sent:   ['', [Validators.required, Validators.pattern(/^[0-9.]*$/)]],
    reference_no:  ['', Validators.required],
    sender_name:   [''],
    deposit_date:  ['', Validators.required],
    deposit_time:  ['', Validators.required]

  });


  calculateDiff(dateSent){
    let currentDate = new Date();
    dateSent = new Date(dateSent);
    let msg = ""

    let totalDays = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) ) /(1000 * 60 * 60 * 24));
  
    if(totalDays>90){
      msg=totalDays +" "+ " Days overdue, Subject for Cancellation";
    }

    else if(totalDays>30 && totalDays < 60){
      msg=totalDays+ " "+ " Days overdue";
    }

    else if(totalDays>60 && totalDays < 90){
      msg=totalDays+ " "+ " Days overdue";
    }

     return msg;
  
  }


  getTotal(){
    this.spinner.show();

    let checked:any = this.unpaid_policy.filter(x=>x.checked==true);
 
    this.selected_policy_count = checked.length;
    this.total_gross= checked.reduce((sumAccessories, current) => +sumAccessories + +current.to_climbs, 0.00);
    this.total_management_fee= checked.reduce((sumAccessories, current) => +sumAccessories + +current.management_fee, 0.00);
    this.endorsement_a= checked.reduce((sumAccessories, current) => +sumAccessories + +current.endorse_debit, 0.00);
    this.endorsement_r= checked.reduce((sumAccessories, current) => +sumAccessories + +current.endorse_credit, 0.00);
    this.amount_to_pay=(this.total_gross - this.total_management_fee);
    this.spinner.hide();
  }

  get_unpaid_policy(){

    let data = {
      branch:this.branch,
      product:this.product,
      insType:this.insType,
      datefrom:this.datefrom,
      dateto:this.dateto,
    }

    setTimeout(() => this.spinner.show(), 0); 

    this.bill.get_unpaid_policy(data).subscribe(res=>{
      this.spinner.hide();
      this.unpaid_policy=res;

    },error=>{
      this.spinner.hide();
      Swal.fire(
        'Error',
        'Something went wrong!',
        'error'
      );
    });
  }


  pay_all(e){
    this.unpaid_policy.forEach(row => {
      row['checked']= e.checked==true? true:false;
    });
  }



  print_bill(){
    this.spinner.show();

    let checked = this.unpaid_policy.filter(x=>x.checked==true);

    if(checked.length==0){
      Swal.fire(
        'Oppps',
        'Please select a policy to pay.',
        'warning'
      );
      this.spinner.hide();

      return
    } 
   

    this.bill.print_bill(checked.map(x=>x.policyno)).subscribe((res:any)=>{
      this.spinner.hide();

      var blob = new Blob([res], {type: 'application/pdf'});
      var downloadURL = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = "bill-statement.pdf";
      link.click();
    },error=>{
        this.spinner.hide();
        Swal.fire(
          'Error',
          'Something went wrong!',
          'error'
        );
    });
  
  }


  save_billing(){
    let checked = this.unpaid_policy.filter(x=>x.checked==true);

    if(checked.length==0){
      Swal.fire(
        'Oppps',
        'Please select a policy to pay.',
        'warning'
      );
      return
    } 
    this.spinner.show();

    this.reference=Math.random().toString(36).substr(2, 9);

    this.files.forEach(element => {
      this.formData.append('file[]', element.file, element.name);
    });

    this.formData.set('cash[login_id]', this.user.iloginId);
    this.formData.set('cash[channel_type]', this.payForm.value.channel_type);
    this.formData.set('cash[channel_name]', this.payForm.value.channel_name);
    this.formData.set('cash[channel_branch]', this.payForm.value.channel_branch);
    this.formData.set('cash[reference_no]', this.payForm.value.reference_no);
    this.formData.set('cash[amount_sent]', this.payForm.value.amount_sent);
    this.formData.set('cash[deposit_date]', this.payForm.value.deposit_date);
    this.formData.set('policies', JSON.stringify(checked.map(x=>x.policyno)));

    this.bill.save_billing(this.formData).subscribe(res=>{
    
      this.frame.hide();
      this.frame1.hide();
      this.spinner.hide();
      this.get_unpaid_policy();
   

      Swal.fire(
        'Success',
        res['msg'],
        'success'
      );

    },error=>{
      this.spinner.hide();
      let err=error.error;
      let msg='';

      Object.keys(err).forEach(function(key){
        msg+=err[key]+'<br/>';
      });

      Swal.fire(
        'Error',
        msg,
        'error'
      );
    });
  }

  pay_using_soa(){
    this.payForm.patchValue({ 
      channel_type: 0, 
      channel_name: 'Climb Account', 
      channel_branch:'Statement of Account', 
      sender_name: this.user.name,
    });
    this.frame1.show();
  }


  pay_using_biling(){
    this.payForm.patchValue({
      channel_branch: null,
    });
 
    this.frame.show();
  }

  

  onUploadOutput(event) {
    this.files.push({ name:event.target.files[0].name, file:event.target.files[0]});
  }


  get_channel_type(){
    this.productHis.get_channel_type().subscribe(res=>{
      this.channel_type = res;
    });
  }

  get_channel_branch(val){
    if(val){
      this.productHis.get_channel_branch(val).subscribe(res=>{
        this.channel_name_list = res;
      });
    }
  }
  branches:any=[]

  selectBranch(){
    this.issuedpolicy.selectBranch(this.user.iloginId).subscribe(res=>{
  
      this.branches =res;
    },error=>{
      console.log(error)
    });
  }


  productsListing(){
    let data ={
      code: this.user.coop_maincode,
      type: this.user.usertype==1 || this.user.usertype==8 || this.user.usertype==12?  [2,3,4,5,6]: [this.user.usertype]
    }

    this.com.displayCom(data).subscribe(res=>{
      this.products = res;
    },error=>{
      Swal.fire(
        'Oopps',
        'Something went wrong.',
        'error'
      );
    });
  }


}
