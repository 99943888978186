import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BillingService } from '../../../services-http/billing/billing.service';
import Swal from 'sweetalert2';
import { FormBuilder, Validators,FormGroup,FormControl } from '@angular/forms';
import { ModalDirective  } from 'ng-uikit-pro-standard';
import { environment } from 'src/environments/environment';
import { ProductionHistoryService } from '../../../services-http/production-history/production-history.service';
import { CommissionPremiumSetupService } from '../../../services-http/commission-premium-setup/commission-premium-setup.service';
import { debounceTime } from "rxjs/operators";
import { IMyOptions } from 'ng-uikit-pro-standard';


@Component({
  selector: 'app-admin-billing-generate',
  templateUrl: './admin-billing-generate.component.html',
  styleUrls: ['./admin-billing-generate.component.scss']
})
export class AdminBillingGenerateComponent implements OnInit {
  @ViewChild('frame1') frame1: ModalDirective;
  @ViewChild('frame') frame: ModalDirective;

  validatingForm = new FormGroup({
    search : new FormControl('', Validators.required),
  });

  constructor(
    private spinner: NgxSpinnerService, 
    private bill:BillingService,
    private fb: FormBuilder, 
    private productHis:ProductionHistoryService,
    private com:CommissionPremiumSetupService,
  ) { }

  ngOnInit() {
    this.get_channel_type();

    this.validatingForm.get("search").valueChanges.pipe(debounceTime(1000)).subscribe(res => {
      if(res && typeof res == "string" ){
        this.search_user_billing();
      }
    });
  }

  date_from:any;
  date_to:any;
  billlists:any=0;
  user:any = JSON.parse(localStorage.getItem('currentUser'));
  reference:any;
  pdf_url:any;
  formData = new FormData();
  mg_url:any;
  url = environment.url;
  img_url:any;
  bill_id:any;
  show:number=0;
  channel_type:any=[];
  channel_name_list:any=[];
  unpaid_policy:any=[];
  total_gross:any =0;
  total_management_fee:any=0;
  amount_to_pay:any=0;
  files:any=[];
  isShow:any=false;
  variants:any=[];
  searchListShow:any=false;
  searchResult:any=[];
  variantid:any ='';
  selected_policy_count:any=0;
  endorsement_a:any=0;
  endorsement_r:any=0;

  public myDatePickerOptions: IMyOptions = {
    // Your options
  };

  public myDatePickerOptions1: IMyOptions = {
    // Your options
  };

  payForm= this.fb.group({
    channel_type:  ['', Validators.required],
    channel_branch:['', Validators.required],
    channel_name:  ['', Validators.required],
    amount_sent:   ['', [Validators.required, Validators.pattern(/^[0-9.]*$/)]],
    reference_no:  ['', Validators.required],
    sender_name :  [''],
    deposit_date:  ['', Validators.required]
  });


  search_user_billing(){
    this.spinner.show();

    let data={
      name: this.validatingForm.value.search
    };

    this.bill.search_user_billing(data).subscribe((res:any)=>{
      this.spinner.hide();

      this.searchListShow=true;

      this.searchResult = res;
    },error=>{
      this.spinner.hide();
      Swal.fire(
        'Error',
        'Something went wrong!',
        'error'
      );
    });
  }

  patch(val){
    this.validatingForm.patchValue({search: val.login_id});
    this.searchListShow = false;

    this.variant_list(val)
  }

  variant_list(user){      
    this.spinner.show();
    let data ={
      code: user.pkid_value,
      type: [user.login_typeid]
    }

    this.com.displayCom(data).subscribe(res=>{
      this.spinner.hide();
      this.variants = res;

    },error=>{
      this.spinner.hide();
      Swal.fire(
        'Error',
        'Something went wrong!',
        'error'
      );
    });
  }

  

  getTotal(){
    this.spinner.show();

    let checked:any = this.unpaid_policy.filter(x=>x.checked==true);
 
    this.selected_policy_count = checked.length;
    this.total_gross= checked.reduce((sumAccessories, current) => +sumAccessories + +current.to_climbs, 0.00);
    this.total_management_fee= checked.reduce((sumAccessories, current) => +sumAccessories + +current.management_fee, 0.00);
    this.endorsement_a= checked.reduce((sumAccessories, current) => +sumAccessories + +current.endorse_debit, 0.00);
    this.endorsement_r= checked.reduce((sumAccessories, current) => +sumAccessories + +current.endorse_credit, 0.00);
    this.amount_to_pay=(this.total_gross - this.total_management_fee);
    this.spinner.hide();
  }

  get_unpaid_policy(){
    setTimeout(() => this.spinner.show(), 0); 

    let data = {
      login_id: this.validatingForm.value.search,
      variantid: this.variantid
    }

    this.bill.get_unpaid_policy_admin(data).subscribe(res=>{
      this.spinner.hide();
      this.unpaid_policy = res;
    },error=>{
      this.spinner.hide();
      Swal.fire(
        'Error',
        'Something went wrong!',
        'error'
      );
    });
  }




  pay_all(e){
    this.unpaid_policy.forEach(row => {
      row['checked']= e.checked==true? true:false;
    });
  }



  print_bill(){
    let checked = this.unpaid_policy.filter(x=>x.checked==true);

    if(checked.length==0){
      Swal.fire(
        'Oppps',
        'Please select a policy to pay.',
        'warning'
      );
      return
    } 
    this.spinner.show()

    this.bill.print_bill(checked.map(x=>x.policyno)).subscribe((res:any)=>{
      this.spinner.hide();
      var blob = new Blob([res], {type: 'application/pdf'});
      var downloadURL = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = "bill-statement.pdf";
      link.click();
    },error=>{
        this.spinner.hide();
        Swal.fire(
          'Error',
          'Something went wrong!',
          'error'
        );
    });
  
  }


  save_billing(){
    let checked = this.unpaid_policy.filter(x=>x.checked==true);

    if(checked.length==0){
      Swal.fire(
        'Oppps',
        'Please select a policy to pay.',
        'warning'
      );
      return
    } 
    this.spinner.show();

    this.reference=Math.random().toString(36).substr(2, 9);

    this.files.forEach(element => {
      this.formData.append('file[]', element.file, element.name);
    });
    this.formData.set('cash[login_id]', this.validatingForm.value.search);
    this.formData.set('cash[channel_type]', this.payForm.value.channel_type);
    this.formData.set('cash[channel_name]', this.payForm.value.channel_name);
    this.formData.set('cash[channel_branch]', this.payForm.value.channel_branch);
    this.formData.set('cash[reference_no]', this.payForm.value.reference_no);
    this.formData.set('cash[amount_sent]', this.payForm.value.amount_sent);
    this.formData.set('cash[deposit_date]', this.payForm.value.deposit_date);
    this.formData.set('policies', JSON.stringify(checked.map(x=>x.policyno)));

    this.bill.save_billing(this.formData).subscribe(res=>{
    
      this.frame.hide();
      this.frame1.hide();
      this.spinner.hide();
      this.get_unpaid_policy();
   

      Swal.fire(
        'Success',
        res['msg'],
        'success'
      );

    },error=>{
      this.spinner.hide();
      let err=error.error;
      let msg='';

      Object.keys(err).forEach(function(key){
        msg+=err[key]+'<br/>';
      })

      Swal.fire(
        'Error',
        msg,
        'error'
      );
    });
  }

  pay_using_soa(){
    this.payForm.patchValue({ 
      channel_type: 0, 
      channel_name: 'Climb Account', 
      channel_branch:'Statement of Account', 
      sender_name: this.user.name,
    });
    this.frame1.show();
  }


  pay_using_biling(){
    this.payForm.patchValue({
      channel_branch: null,
    });
 
    this.frame.show();
  }

  

  onUploadOutput(event) {
    this.files.push({ name:event.target.files[0].name, file:event.target.files[0]});
  }


  get_channel_type(){
    this.productHis.get_channel_type().subscribe(res=>{
      this.channel_type = res;
    });
  }

  get_channel_branch(val){
    if(val){
      this.productHis.get_channel_branch(val).subscribe(res=>{
        this.channel_name_list = res;
      });
    }
  }



}
