import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class BillingService {
  url = environment.url;
  constructor(private http: HttpClient) { }

  print_bill(data){
    const httpOptions = {
      responseType: ('blob' as 'json')
    };

    return this.http.post(this.url + '/api/billListExport',data,httpOptions);
  }


  credit_cashin_list(page, searchText){
    return this.http.get(this.url + '/credit_cashin_list?page='+page+'&search='+searchText);
  }

  reload_credit_line(data){
    return this.http.post(this.url + '/reload_credit_line',data);
  }

  request_credit_cashIn(data){
    return this.http.post(this.url + '/request_credit_cashIn',data);
  }

  save_billing(data){
    return this.http.post(this.url + '/save_billing',data);
  }

  billing_list(){
    return this.http.get(this.url + '/billing_list');
  }

  credit_points(){
    return this.http.get(this.url + '/credit_points');
  }

  credit_transaction_history(page,type){
    return this.http.get(this.url + '/credit_transaction_history?type='+type+'&page='+page);
  }

  // pay_credit_bill(data){
  //   return this.http.post(this.url + '/pay_credit_bill',data);
  // }


  checkPoints(data){
    return this.http.post(this.url + '/checkPoints',data);
  }



  searchCoopInfo(searchText){
    return this.http.get(this.url + '/searchCoopInfo?searchText='+searchText);
  }


  reload_by_officer(data){
    return this.http.post(this.url + '/reload_by_officer', data);
  }



  get_unpaid_policy(data){
    return this.http.post(this.url + '/get_unpaid_policy',data);
  }


  export_trans_history(data){
    return this.http.post(this.url + '/export_trans_history',data);
  }

  search_user_billing(data){
    return this.http.post(this.url + '/search_user_billing',data);

  }


  get_unpaid_policy_admin(data){
    return this.http.post(this.url + '/get_unpaid_policy_admin',data);
  }


  adminTransactionHistory(data){
    return this.http.post(this.url + '/adminTransactionHistory',data);


  }

  adminTransactionHistoryUsers(){
    return this.http.get(this.url + '/adminTransactionHistoryUsers');


  }

  adminTransactionHistoryCoop(){
    return this.http.get(this.url + '/adminTransactionHistoryCoop');

  }






  

}
