import { Component, OnInit,ViewChild  } from '@angular/core';
import { AuthService } from '../../../services-http/auth/auth.service';
import { Router } from '@angular/router'; 
import { UserService } from '../../../services-http/user/user.service';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { NotificationService } from '../../../services-http/notification/notification.service';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  @ViewChild('frame') frame: ModalDirective;
  coopName:any;
  user:any = JSON.parse(localStorage.getItem('currentUser'));


  constructor(
    private authService: AuthService,
    private router: Router,
    private userService:UserService,
    public notification:NotificationService
  ) { }

  ngOnInit() {
    // this.getCoopName();
    this.notification.getNotification(this.user.iloginId);
  }


  logout() {
    this.authService.logout();
  }

   
  gotoProfileView() {
    this.router.navigateByUrl('/admin/profile/' + this.user['userId'] + '/view');
  }

  // getCoopName(){
  //   this.userService.getCoopName(this.user['userId']).subscribe(res => {
  //     this.coopName=res;
  //   });
  // }

  notificationRead(notification){
    this.notification.notificationRead(notification).subscribe(res =>{
      this.notification.getNotification(this.user.iloginId);
    });
  }

  refresh(){
    this.notification.getNotification(this.user.iloginId);
  }



}
