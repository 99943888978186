import { Component, OnInit, ViewChild } from '@angular/core';
import { OrIssuanceService } from '../../../services-http/orIssuance/or-issuance.service';
import { ToastService  } from 'ng-uikit-pro-standard';
import { ProductionHistoryService } from '../../../services-http/production-history/production-history.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { debounceTime } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { ModalDirective  } from 'ng-uikit-pro-standard';


@Component({
  selector: 'app-or-issuance',
  templateUrl: './or-issuance.component.html',
  styleUrls: ['./or-issuance.component.scss']
})

export class OrIssuanceComponent implements OnInit {
  @ViewChild('frame') frame: ModalDirective;

  constructor(
    private or:OrIssuanceService, 
    private toastrService: ToastService , 
    private productHis: ProductionHistoryService,
    private spinner: NgxSpinnerService
    ) { }


  // searchForm = new FormGroup({
  //   search: new FormControl('')
  // });

  searchListShow:boolean = false;
  elements:any =[];
  data:any={data:[]};
  posting_type:number=2;
  search:any='';
  headElements = ['','Name/ Coop Name', 'Particular','Posting Type','Date Paid','PR/Bill No.','Amount','Remove'];
  or_no:any;
  date:any;
  options:any = { opacity: 0 };
  area:any = '';
  orIssuanceAreas:any =[];
  classification:any ='2'
  l = new Date();
  dateto = this.l.toISOString().substring(0,10);
  f = new Date("2013-01-01");
  datefrom = this.f.toISOString().substring(0,10);
  page:number=1;
  isLoading:boolean = false;
  list_of_checked_policy:any=[];



  ngOnInit() {
    // this.display(1,false);

    this.orIssuanceArea();

    // this.searchForm.get('search').valueChanges.pipe(debounceTime(2000)).subscribe(res => {
   
    //   this.display(1,false);
    // });
  }


  display(page, expor){

    if(this.search==""){
      this.searchListShow = false;
      return 
    }

    this.isLoading = true;
    this.searchListShow =true;

    let data = {
      search: this.search,
      posting_type:this.posting_type,
      page:page,
      area:this.area,
      datefrom:this.datefrom,
      dateto:this.dateto,
      export: expor,
      classification: this.classification
    }

    this.or.display(data).subscribe((res:any)=>{
      this.isLoading = false;
    
      if(!expor){
        this.data=[];
        this.data = res;

        return; 
      }

      window.open(res);

    },error=>{
      this.isLoading = false;
    });
  }




  saveOR(){
    let data = {
      date: this.date,
      or_no: this.or_no,
      posting_type: this.posting_type,
      data: this.list_of_checked_policy
    }

    this.or.saveOR(data).subscribe((res:any)=>{
      
      Swal.fire(
        'Success',
        res.msg,
        'success'
      );
  
      this.or_no='';
      this.date='';
      this.frame.hide();
      this.list_of_checked_policy=[];
      this.search="";

    },error=>{
      Swal.fire(
        'Error',
        'Something went wrong!',
        'error'
      );
    });
  }



  addPolicy(){
    let check = this.data.data.filter(x=>x.checked == true);

    check.forEach(row => {
      let exist=this.list_of_checked_policy.find(x=>x.keyid==row.keyid);

      if(!exist){
        this.list_of_checked_policy.push(row); 
      }
    });
    close();
    
  }



  print_paid_bill(bill_id){
    this.spinner.show();
    this.productHis.print_paid_bill(bill_id).subscribe((res:any) => {
      this.spinner.hide();
      var blob = new Blob([res], {type: 'application/pdf'});
      var downloadURL = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = "paid-bill-statement.pdf";
      link.click();

    },error=>{
      console.log(error)
    });
  }



  orIssuanceArea(){
    this.or.orIssuanceArea().subscribe(res=>{
      this.orIssuanceAreas= res;
    },error=>{
      console.log(error)
    });
  }


  // pay_all(e, i){
    
  //   this.data.data[i].policies.forEach(row => {
  //     // row['checked']= e.checked==true? true:false;

  //     if(e.checked&&row.or_no==''){
  //       row['checked']=true;
  //     }
  //     else if(!e.checked&&row.or_no==''){
  //       row['checked']=false;
  //     }
      
  //   });

  // }


  pay_all(e){
    this.data.data.forEach(row => {
      row['checked'] = e.checked==true? true:false;
    });
  }


  close(){
    this.searchListShow=false;
    this.search="";
  }


  

}
