import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductionHistoryService } from '../../../services-http/production-history/production-history.service'
import { FormBuilder,Validators,FormControl,FormGroup } from '@angular/forms';
import Swal from 'sweetalert2'
import { ModalDirective } from 'ng-uikit-pro-standard';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment.prod';
import { debounceTime } from "rxjs/operators";


@Component({
  selector: 'app-endorsement',
  templateUrl: './endorsement.component.html',
  styleUrls: ['./endorsement.component.scss']
})
export class EndorsementComponent implements OnInit {
  @ViewChild('basicModal') basicModal: ModalDirective;
  @ViewChild('basicModal4') basicModal4: ModalDirective;

  constructor(
    private product: ProductionHistoryService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService
  ) { }

  endorsementForm = this.fb.group({
    policyNo: ['',Validators.required],
    remarks: ['',Validators.required],
    memo: ['',Validators.required],
    endorsement_no: [{value:'', disabled: true},Validators.required],
    // endorsement_no: ['',Validators.required],
    w_benefit:0
  });


  other_fees = new FormGroup({
    total_prem: new FormControl(0.00, [Validators.required, Validators.pattern(/^[-\d]+(\.\d+)?$/)]),
    doc_stamp: new FormControl(0.00, [Validators.required, Validators.pattern(/^[-\d]+(\.\d+)?$/)]),
    misc_fee: new FormControl(0.00, [Validators.required, Validators.pattern(/^[-\d]+(\.\d+)?$/)]),
    vat: new FormControl(0.00, [Validators.required, Validators.pattern(/^[-\d]+(\.\d+)?$/)]),
    lgt: new FormControl(0.00, [Validators.required, Validators.pattern(/^[-\d]+(\.\d+)?$/)]),
    d_tech: new FormControl(0.00, [Validators.required, Validators.pattern(/^[-\d]+(\.\d+)?$/)]),
    comp_fee: new FormControl(0.00, [Validators.required, Validators.pattern(/^[-\d]+(\.\d+)?$/)]),
    amnt_due: new FormControl(0.00, [Validators.required, Validators.pattern(/^[-\d]+(\.\d+)?$/)]),
    fst: new FormControl(0.00, [Validators.required, Validators.pattern(/^[-\d]+(\.\d+)?$/)])
  });

  get total_prem() { return this.other_fees.get('total_prem'); }
  get doc_stamp() { return this.other_fees.get('doc_stamp'); }
  get misc_fee() { return this.other_fees.get('misc_fee'); }
  get vat() { return this.other_fees.get('vat'); }
  get lgt() { return this.other_fees.get('lgt'); }
  get d_tech() { return this.other_fees.get('d_tech'); }
  get comp_fee() { return this.other_fees.get('comp_fee'); }
  get amnt_due() { return this.other_fees.get('amnt_due'); }
  get fst() { return this.other_fees.get('fst'); }
  

  date = new Date();
  headElements:any = ['Policy No','Endorsement No','Remarks','Memo','Endorsed By','Endorsement Date' ,'Action']
  endorsements:any = [];
  url=environment.url;
  search_result:any = [];
  searchText:any='';
  edits:any=false;
  edit_data:any;
  user = JSON.parse(localStorage.getItem("currentUser"));
  email:any='';
  endorse_id:any='';
  quoteDetail:any=[];

  l = new Date();
  dateto = this.l.toISOString().substring(0,10);
  f = new Date("2019-01-01");
  datefrom = this.f.toISOString().substring(0,10);

  ngOnInit() {
    // console.log(this.datefrom)
    this.get_endorsement(1,false);

    this.endorsementForm.get('policyNo').valueChanges.pipe(debounceTime(2000)).subscribe(value => {
      if(value && this.edits==false){
        this.searchPolicy();
      }
    },error=>{
      this.spinner.hide();
      
      Swal.fire(
        'Error',
        'Something went wrong!',
        'error'
      );
    });
  }




  get_endorsement(pageno, exports){
    setTimeout(() => this.spinner.show(), 0); 

    let data = {
      page: pageno,
      searchKey: this.searchText,
      export: exports,
      dateto: this.dateto,
      datefrom: this.datefrom,

    } 

    this.product.endorsement(data).subscribe((res:any)=>{
      this.spinner.hide();
      this.basicModal4.hide();

      if(exports){
        // console.log(res)
        window.open(res);
        return;
      }

      this.endorsements=res;

    },error=>{
      this.spinner.hide();
      this.basicModal4.hide();

    });
  }



  searchPolicy(){
    this.spinner.show()
    this.search_result = '';
    this.product.searchPolicy({policyno:this.endorsementForm.value.policyNo}).subscribe( (res:any)=>{
      this.spinner.hide();
      this.search_result = res;

      if(this.search_result==0){
        this.endorsementForm.reset();
        Swal.fire(
          'Opppss',
          'Policy no. not found in the database!',
          'warning'
        );
        return false;
      }
      this.search_result.benefits.forEach(row => {
        row.coverage=0.00;
        row.premium=0.00;
      });

      this.endorsementForm.patchValue({
          endorsement_no: res['policyno'] + "-" + (+res['endorsement_count'] + 1)
        
      });

    },error=>{
      this.spinner.hide()
      Swal.fire(
        'Oppps',
        'Something went wrong!',
        'error'
      );
    });
  }


  save(){
    this.spinner.show()
    let endorsement = {
      endorsement_no: this.endorsementForm.getRawValue().endorsement_no,
      memo: this.endorsementForm.getRawValue().memo,
      policyNo: this.endorsementForm.getRawValue().policyNo,
      remarks: this.endorsementForm.getRawValue().remarks,
      w_benefit: this.endorsementForm.value.w_benefit==true? 1:0,
      amnt_due: this.other_fees.value.amnt_due,
      comp_fee: this.other_fees.value.comp_fee,
      d_tech: this.other_fees.value.d_tech,
      doc_stamp: this.other_fees.value.doc_stamp,
      lgt: this.other_fees.value.lgt,
      misc_fee: this.other_fees.value.misc_fee,
      total_prem: this.other_fees.value.total_prem,
      vat: this.other_fees.value.vat,
      login_id: this.search_result.agentNo,
      endorseBy: this.user.iloginId,
      fst: this.other_fees.value.fst
    }

    let data = {
      endorsement:endorsement,
      benefit:this.search_result.benefits
    }

    this.product.save( data ).subscribe(res=>{
    
      this.basicModal.hide();
      this.get_endorsement(1,false)
      this.endorsementForm.reset()
      this.search_result = ''
      this.other_fees.reset()
      this.spinner.hide()
      Swal.fire(
        'Success',
        res['msg'],
        'success'
      );
    },error=>{
      this.spinner.hide()

      Swal.fire(
        'Opppss',
        'Something went wrong, try again later!',
        'error'
      );
    });

  }


  // cancel_endorsement( id ){
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: "You won't be able to revert this!",
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes, delete it!'
  //   }).then((result) => {
  //     if (result.value) {
  //       this.spinner.show()
  //       this.product.cancel_endorsement(id).subscribe(res=>{
  //         this.get_endorsement(1,false);
  //         this.spinner.hide()
  //       })
  //     }
  //   });
   
  // }


  edit(data){

    this.search_result =[];
    this.edit_data = data;
    this.endorsementForm.patchValue(data);
    this.endorsementForm.patchValue({w_benefit: +data.w_benefit });

    this.other_fees.patchValue(data);
    this.search_result={benefits: data.benefits};
 
    // this.product.edit(this.edit_data.id).subscribe( res=>{
    //   console.log(res)
    //   this.search_result = res;
    //   this.spinner.hide()

    // },error=>{
    //   this.spinner.hide();
    // })
  }
  

  endorsementPrint(id){
    this.spinner.show();

    this.product.endorsementPrint(id).subscribe((res:Blob)=>{
      this.spinner.hide();

      var blob = new Blob([res], {type: 'application/pdf'});
      var downloadURL = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = "endorsement-letter.pdf";
      link.click();
    },error=>{
      this.spinner.hide();
    });
  }



  update(){
    this.spinner.show()
    let endorsement = {
      endorsement_no: this.endorsementForm.getRawValue().endorsement_no,
      memo: this.endorsementForm.getRawValue().memo,
      policyNo: this.endorsementForm.getRawValue().policyNo,
      remarks: this.endorsementForm.getRawValue().remarks,
      w_benefit: this.endorsementForm.getRawValue().w_benefit==true? 1: 0,
      amnt_due: this.other_fees.value.amnt_due,
      comp_fee: this.other_fees.value.comp_fee,
      d_tech: this.other_fees.value.d_tech,
      doc_stamp: this.other_fees.value.doc_stamp,
      lgt: this.other_fees.value.lgt,
      misc_fee: this.other_fees.value.misc_fee,
      total_prem: this.other_fees.value.total_prem,
      vat: this.other_fees.value.vat,
    }

    let data = {
      endorsement:endorsement,
      benefit:this.search_result.benefits
    }

    this.product.update( data, this.edit_data.id ).subscribe( res=>{
      this.basicModal.hide();
      this.get_endorsement(1,false)
      this.search_result = ''
      this.other_fees.reset()
      this.spinner.hide()

      Swal.fire(
        'success',
        'Successfully updated endorsement!',
        'success'
      );
    },error=>{
      this.spinner.hide()

      let err=error.error;
      let msg='';

      Object.keys(err).forEach(function(key){
        msg+=err[key]+'<br/>';
      })

      Swal.fire(
        'Error',
        msg,
        'error'
      );
    
    })
  }


  on_select(){
    if(this.endorsementForm.getRawValue().w_benefit == false){
      this.endorsementForm.getRawValue().w_benefit = null;
    }
  }

  

  email_endorsement(){
    this.spinner.show()

    this.product.email_endorsement(this.email,this.endorse_id).subscribe(res=>{
      this.spinner.hide()
      Swal.fire(
        'success',
        res['msg'],
        'success'
      );
    },error=>{
      this.spinner.hide()
      Swal.fire(
        'Opps',
        'Something went wrong!',
        'error'
      );
    }
    )
  }



  getQuotationDetails(quoteId){
    this.spinner.show()

    this.product.getQuotationDetails(quoteId).subscribe(res=>{
      this.spinner.hide()
      this.quoteDetail=res;
    },error=>{
      this.spinner.hide()
      Swal.fire(
        'Opps',
        'Something went wrong!',
        'error'
      );
    });
  }




  



}
